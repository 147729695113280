import { render, staticRenderFns } from "./Interactive.vue?vue&type=template&id=ffba0534&scoped=true&"
import script from "./Interactive.vue?vue&type=script&lang=js&"
export * from "./Interactive.vue?vue&type=script&lang=js&"
import style0 from "./Interactive.vue?vue&type=style&index=0&id=ffba0534&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffba0534",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton} from 'bootstrap-vue'
    installComponents(component, {BButton})
    

export default component.exports